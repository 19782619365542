import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Index from "../pages/Index";
// import Home from "../pages/Home";
// import About from "../pages/About";
// import Service from "../pages/Service";
// import Contact from "../pages/Contact";
// import Navbar from "../components/Navbar/Navbar";

const MyRoute = () => {
  return (
    <Router>
      {/* <Navbar /> */}
      <Routes>
        <Route index element={<Index />} />
        {/* <Route path="about" element={<About />} />
        <Route path="service" element={<Service />} />
        <Route path="contact" element={<Contact />} /> */}
      </Routes>
    </Router>
  );
};

export default MyRoute;
