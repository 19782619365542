import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Col, Row } from "reactstrap";

const images = [
  {
    src: "ecopetrol.png",
  },
  {
    src: "dian.jpg",
  },
  {
    src: "agricultura.png",
  },
  {
    src: "cci.png",
  },
  {
    src: "transporte.png",
  },
  {
    src: "ani.png",
  },
  {
    src: "invias.png",
  },
  // {
  //   src: "valledupar.png",
  // },
  // {
  //   src: "sanamb.png",
  // },
];

export const Client = ({ deviceType }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 4, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <div className="mb-5">
      <div className="container" id="clients">
        <Row>
          <Col lg="4">
            <div className="title-heading mb-5">
              <h3 className="text-dark font-weight-light text-uppercase">
                Nuestros Clientes
              </h3>
              <div className="title-border-simple position-relative" />
            </div>
          </Col>
        </Row>
      </div>

      <div className="container-fluid">
        <Carousel
          swipeable={true}
          draggable={false}
          showDots={false}
          responsive={responsive}
          ssr={true}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={3000}
          keyBoardControl={true}
          customTransition="transform 5s ease-in-out"
          transitionDuration={5000}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile"]}
          deviceType={deviceType}
          additionalTransfrom={5}
          minimumTouchDrag={50}
          arrows={true}
        >
          {images.map((image, index) => (
            <div key={index}>
              <img
                className="carousel-image"
                src={`/images/custom/clients/${image.src}`}
                alt=""
              />
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};
