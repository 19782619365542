import React from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import {
  FaReact,
  FaDatabase,
  FaLaptopCode,
  FaLock,
  FaUniversity,
  FaSoundcloud,
  FaBookReader,
} from "react-icons/fa";
import { MdVisibility } from "react-icons/md";
import { RiServerFill } from "react-icons/ri";
import { TiGroupOutline, TiNews } from "react-icons/ti";
import { SiMicrosoftazure } from "react-icons/si";

const Service = () => {
  const ServiceList = [
    {
      icon: <FaLaptopCode />,
      title:
        "Desarrollo de software, plataformas y aplicaciones a la medida en el ámbito empresarial",
      content: "Compramos y vendemos los residuos reciclables y chatarra.",
    },
    {
      icon: <FaDatabase />,
      title: "Administración y gestión de bases de datos",
      content:
        "Recogemos todo material reciclable de su empresa o negocio (cartón, vidrio, metal, papel, etc).",
    },
    {
      icon: <FaUniversity />,
      title: "Implementación de arquitecturas empresariales y Gobierno TI",
      content:
        "Recogemos  el  material  reciclable  en  la  puerta  de  su  negocio,  empresa  o  institución  en horarios y frecuencias correspondientes a la ruta de su sector.",
    },
    {
      icon: <FaReact />,
      title:
        "Administración e Implementación de entornos y ambiente virtuales educativos.",
      content:
        "A  través  de  la  celebración  de  contratos  o  convenios  con  empresas  publicas,  privadas, \n" +
        "organizaciones  e  instituciones,  desarrollamos  actividades  de  restauración  de  áreas \n" +
        "degradadas a través de la reforestación.",
    },
    {
      icon: <FaSoundcloud />,
      title:
        "Administración, gestión e implementación de servicios en la nube.",
      content:
        "A  través  de  la  celebración  de  contratos  o  convenios  con empresas  públicas,  privadas, \n" +
        "organizaciones,  instituciones,  desarrollamos  actividades  de  limpieza,  barrido  de  vías  , \n" +
        "parques, zonas verdes, bodegas y su empresa en general.",
    },
    {
      icon: <FaLock />,
      title: "Seguridad informática",
      content:
        "Desarrollamos programas de educación ambiental comunitaria a las instituciones educativas y \n" +
        "empresas en gestión de residuos sólidos y reciclaje, a través de charlas, talleres, campañas \n" +
        "informativas con entrega de material educativo.",
    },
    {
      icon: <FaBookReader />,
      title: "Capacitaciones especializadas TI",
      content:
        "Desarrollamos programas de educación ambiental comunitaria a las instituciones educativas y \n" +
        "empresas en gestión de residuos sólidos y reciclaje, a través de charlas, talleres, campañas \n" +
        "informativas con entrega de material educativo.",
    },
    {
      icon: <TiGroupOutline />,
      title: "Consultorías TI",
      content:
        "Desarrollamos programas de educación ambiental comunitaria a las instituciones educativas y \n" +
        "empresas en gestión de residuos sólidos y reciclaje, a través de charlas, talleres, campañas \n" +
        "informativas con entrega de material educativo.",
    },
    {
      icon: <TiNews />,
      title: "Implementación de Buenas Practicas TI.",
      content:
        "Desarrollamos programas de educación ambiental comunitaria a las instituciones educativas y \n" +
        "empresas en gestión de residuos sólidos y reciclaje, a través de charlas, talleres, campañas \n" +
        "informativas con entrega de material educativo.",
    },
    {
      icon: <RiServerFill />,
      title:
        "Administración, gestión e implementación de infraestructura tecnológica",
      content:
        "Desarrollamos programas de educación ambiental comunitaria a las instituciones educativas y \n" +
        "empresas en gestión de residuos sólidos y reciclaje, a través de charlas, talleres, campañas \n" +
        "informativas con entrega de material educativo.",
    },
    {
      icon: <MdVisibility />,
      title: "Auditorias de sistemas",
      content:
        "Desarrollamos programas de educación ambiental comunitaria a las instituciones educativas y \n" +
        "empresas en gestión de residuos sólidos y reciclaje, a través de charlas, talleres, campañas \n" +
        "informativas con entrega de material educativo.",
    },
    {
      icon: <SiMicrosoftazure />,
      title: "Servicios Microsoft Azure",
      content:
        "Desarrollamos programas de educación ambiental comunitaria a las instituciones educativas y \n" +
        "empresas en gestión de residuos sólidos y reciclaje, a través de charlas, talleres, campañas \n" +
        "informativas con entrega de material educativo.",
    },
  ];
  return (
    <>
      <section className="section " id="services">
        <div className="container">
          <Row>
            <Col lg="12">
              <div className="title-heading mb-5">
                <h3 className="mb-1 font-weight-light text-uppercase text-dark">
                  Servicios
                </h3>
                <div className="title-border-simple position-relative" />
              </div>
            </Col>
          </Row>

          <Row>
            {ServiceList.map((service, index) => {
              return (
                <Col lg="4" md="6" key={index}>
                  <div className="service-box rounded mt-4 p-4">
                    <div className="service-icon mb-3">
                      <i>{service.icon}</i>
                    </div>
                    <div className="services-desc">
                      <div className="service-title mb-2 position-relative">
                        <h5 className="font-weight-normal mb-3">
                          <Link to="#" className="text-dark">
                            {service.title}
                          </Link>
                        </h5>
                      </div>
                      {/* <p className="text-muted mb-3 f-14">{service.content}</p> */}
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      </section>
    </>
  );
};

export default Service;
