import React from "react";
import MyRoute from "./routers/router";

const App = () => {
  return (
    <>
      <MyRoute />
    </>
  );
};

export default App;
