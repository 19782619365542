import React, { useRef } from "react";
// import { AvForm, AvField } from "availity-reactstrap-validation";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import emailjs from "@emailjs/browser";
import { FiSend } from "react-icons/fi";
import "./contact.css";

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_13v2h6o",
        "template_2lufjse",
        form.current,
        "HxLyYio0jBPqlXUiE"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <>
      <section className="section bg-light" id="contact">
        <div className="container">
          <div className="row">
            <Col lg="12">
              <div className="title-heading mb-5">
                <h3 className="text-dark mb-1 font-weight-light text-uppercase">
                  Contactenos
                </h3>
                <div className="title-border-simple position-relative"></div>
              </div>
            </Col>
          </div>

          <Row>
            <Col lg="12">
              <div className="contact-box p-5">
                <Row>
                  <Col lg="8" md="6">
                    <div className="custom-form p-3">
                      <div id="message"></div>
                      <form
                        className="contact__form"
                        ref={form}
                        onSubmit={sendEmail}
                        id="contact-form"
                      >
                        {/* <AvForm id="contact-form"> */}
                        <Row>
                          <Col lg="6">
                            <div className="form-group app-label">
                              <input
                                name="user_name"
                                type="text"
                                required
                                placeholder="Nombre"
                                className="form__control"
                              />
                            </div>
                          </Col>
                          <Col lg="6">
                            <div className="form-group app-label">
                              <input
                                name="user_email"
                                type="email"
                                required
                                placeholder="Email"
                                className="form__control"
                              />
                            </div>
                          </Col>
                          <Col lg="12">
                            <div className="form-group app-label">
                              <input
                                name="subject"
                                type="text"
                                required
                                placeholder="Tu asunto"
                                className="form__control"
                              />
                            </div>
                          </Col>
                          <Col lg="12">
                            <div className="form-group app-label">
                              <input
                                name="message"
                                type="textarea"
                                required
                                rows="5"
                                placeholder="Mensaje"
                                className="form__control textarea"
                              />
                            </div>
                          </Col>
                          <Row>
                            <Col sm="12">
                              &nbsp; &nbsp;{" "}
                              <button type="submit" className="button">
                                Send Mesage
                                <span className="button__icon contact__button-icon">
                                  <FiSend />
                                </span>
                              </button>
                              <div id="simple-msg"></div>
                            </Col>
                          </Row>
                        </Row>
                        {/* </AvForm> */}
                      </form>
                    </div>
                  </Col>

                  <div className="col-lg-4 col-md-6">
                    <div className="contact-cantent p-3">
                      <div className="contact-details">
                        <div className="float-left contact-icon mr-3 mt-2">
                          <i className="mdi mdi-headphones text-muted h5"></i>
                        </div>
                        <div className="app-contact-desc text-muted pt-1">
                          <p className="mb-0 info-title f-13">Telefono :</p>
                          <p className="mb-0 f-13">300 8982322</p>
                        </div>
                      </div>

                      <div className="contact-details mt-2">
                        <div className="float-left contact-icon mr-3 mt-2">
                          <i className="mdi mdi-email-outline text-muted h5"></i>
                        </div>
                        <div className="app-contact-desc text-muted pt-1">
                          <p className="mb-0 info-title f-13">Email :</p>
                          <p className="mb-0 f-13">
                            <Link to="" className="text-muted">
                              gerencia@globaledb.com
                            </Link>
                          </p>
                        </div>
                      </div>

                      <div className="contact-details mt-2">
                        <div className="float-left contact-icon mr-3 mt-2">
                          <i className="mdi mdi-map-marker text-muted h5"></i>
                        </div>
                        <div className="app-contact-desc text-muted pt-1">
                          <p className="mb-0 info-title f-13">Localización :</p>
                          <p className="mb-0 f-13">
                            <Link to="" className="text-muted">
                              Bogota DC
                            </Link>
                          </p>
                        </div>
                      </div>

                      <div className="follow mt-4">
                        <h4 className="text-dark mb-3">Redes Sociales</h4>
                        <ul className="follow-icon list-inline mt-32 mb-0">
                          <li className="list-inline-item f-15">
                            <Link to="#" className="social-icon text-muted">
                              <i className="mdi mdi-facebook"></i>
                            </Link>
                          </li>
                          &nbsp;
                          <li className="list-inline-item f-15">
                            <Link to="#" className="social-icon text-muted">
                              <i className="mdi mdi-twitter"></i>
                            </Link>
                          </li>
                          &nbsp;
                          <li className="list-inline-item f-15">
                            <Link to="#" className="social-icon text-muted">
                              <i className="mdi mdi-google-plus"></i>
                            </Link>
                          </li>
                          &nbsp;
                          <li className="list-inline-item f-15">
                            <Link to="#" className="social-icon text-muted">
                              <i className="mdi mdi-linkedin"></i>
                            </Link>
                          </li>
                          &nbsp;
                          <li className="list-inline-item f-15">
                            <Link to="#" className="social-icon text-muted">
                              <i className="mdi mdi-whatsapp"></i>
                            </Link>
                          </li>
                          &nbsp;
                        </ul>
                      </div>
                    </div>
                  </div>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default Contact;
