import React from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
// import MisionVisionValuesSection from "./MisionVisionValuesSection";

const About = () => {
  const AboutList = [
    {
      icon: "mdi mdi-lightbulb",
      title: "Misión",
      content:
        "Desarrollar productos tecnológicos empresariales adaptados a la medida, con enfoque global y diferencial apoyados de técnicas, métricas, estándares, herramientas y un talento altamente humano.",
    },
    {
      icon: "mdi mdi-projector-screen",
      title: "Visión",
      content:
        "Ejercer el liderazgo en el sector y la industria de las tecnologías de la infomación y comunicación a nivel nacional e internacional, participando en proyectos de gran escala e impacto para la sociedad.",
    },
    {
      icon: "mdi mdi-nature",
      title: "Valores",
      content:
        "Compromiso, eficiencia, responsabilidad, confiabilidad, respeto",
    },
  ];
  return (
    <>
      <section className="section bg-about" id="about">
        <div className="container">
          <Row>
            <Col lg="12">
              <div className="title-heading mb-5">
                <h3 className="mb-1 font-weight-light text-uppercase text-dark">
                  Sobre nosotros
                </h3>
                <div className="title-border-simple position-relative" />
              </div>
            </Col>
          </Row>

          <div className="row">
            <Row>
              {AboutList.map((about, index) => {
                return (
                  <Col lg="4" key={index}>
                    <div className="about-box text-center p-3">
                      <div className="about-icon mb-4">
                        <i className={about.icon}></i>
                      </div>
                      <h4 className="font-weight-light">
                        <Link to="#" className="text-dark">
                          {about.title}
                        </Link>
                      </h4>
                      <p className="text-dark-50 f-14">{about.content}</p>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>

          <div className="row align-items-center mt-5">
            <Col md="6">
              <div className="about-desc">
                <h5 className=" mb-3 font-weight-light text-dark">
                  Nos dedicamos a desarrollar soluciones tecnológicas desde la
                  documentación hasta implementación con las ultimas normas y
                  estándares que se lideran en la industria. Pese al ritmo
                  vertiginoso que predomina en el sector nos caracterizamos por
                  estar actulizados con las herraminetas novedosa que marcan las
                  pautas en el mercado.
                </h5>
                {/* <p className="-50 f-17 text-white">
                  Nos dedicamos a desarrollar soluciones tecnológicas desde la
                  documentación hasta implementación con las ultimas normas y
                  estándares que se lideran en la industria. Pese al ritmo
                  vertiginoso que predomina en el sector nos caracterizamos por
                  estar actulizados con las herraminetas novedosa que marcan las
                  pautas en el mercado.
                </p> */}
              </div>
            </Col>

            <Col md="6">
              <div className="about-img position-relative p-4">
                <img
                  src="images/blog/img-2.jpg"
                  alt=""
                  className="img-fluid mx-auto d-block"
                />
              </div>
            </Col>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
