import React from "react";
import { Row, Col } from "reactstrap";

const Footer = () => {
  return (
    <>
      <section className="footer-bg">
        <div className="container">
          <Row>
            <Col lg="4">
              <div className="mb-5">
                <p className="text-uppercase text-dark footer-title mb-4">
                  Sobre Nosotros
                </p>
                <p className="text-muted f-14">
                  La empresa fue pensada inicialmente para desarrollar
                  aplicaciones en los diferentes dispositivos, pero en la medida
                  que la industria de las TI evoluciona las necesidades han
                  venido cambiando del cielo a la tierra, de manera que hemos
                  ampliado nuestro portafolio de servicio, incluyendo todos los
                  aspectos que abarcan o hacen referencia a la transformación
                  digital y 4RI.
                </p>
              </div>
            </Col>

            <Col lg="4">
              <Row>
                <Col lg="12">
                  <div className="mb-5">
                    <p className="text-uppercase text-dark footer-title mb-4">
                      Contacto
                    </p>
                    <ul className="list-unstyled footer-sub-menu">
                      <li className="f-14 ">
                        <a className="text-muted d-flex align-items-center flex-row">
                          <i className="fas fa-phone-alt fa-2x" />
                          <span className="ml-2">300 8982322</span>
                        </a>
                      </li>

                      <li className="f-14">
                        <a className="text-muted d-flex align-items-center flex-row">
                          <i className="fas fa-map-marker-alt fa-2x" />
                          <span className="ml-2">Bogota DC</span>
                        </a>
                      </li>

                      <li className="f-14">
                        <a className="text-muted d-flex align-items-center flex-row">
                          <i className="far fa-envelope fa-2x" />
                          <span className="ml-2">gerencia@globaledb.com</span>
                        </a>
                      </li>

                      {/* <li className="f-14">
                        <a className="text-muted d-flex align-items-center flex-row">
                          <i className="fas fa-envelope fa-2x" />
                          <span className="ml-2">
                            coorrenacervalledupar@gmail.com
                          </span>
                        </a>
                      </li> */}
                    </ul>
                  </div>
                </Col>
              </Row>
            </Col>

            {/* <Col lg="4">
              <Row>
                <Col lg="12">
                  <div className="mb-5">
                    <p className="text-uppercase text-dark footer-title mb-4">
                      Redes sociales
                    </p>
                    <ul className="list-unstyled footer-sub-menu">
                      <li className="f-14 ">
                        <a
                          target="_blank"
                          href="https://www.facebook.com/profile.php?id=100008292829014"
                          className="text-muted d-flex align-items-center flex-row"
                        >
                          <i className="fab fa-facebook fa-2x" />
                          <span className="ml-2">
                            Coorrenacer Reciclaje Organizado
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Col> */}
          </Row>
        </div>
      </section>
    </>
  );
};

export default Footer;
