import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ScrollspyNav from "../scrollSpy";
import "./Navbar.css";

const Navbar = ({ isAuth, isAdmin }) => {
  return (
    <>
      <nav
        id="main_navbar"
        className="navbar navbar-expand-lg  fixed-top navbar-custom sticky sticky-dark"
      >
        <div className="container">
          <Link className="navbar-brand logo" to="/">
            <img src="images/custom/logo.png" alt="" height="60" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="mdi mdi-menu" />
          </button>

          <div className="collapse navbar-collapse" id="navbarCollapse">
            <ScrollspyNav
              scrollTargetIds={["home", "about", "services", "contact"]}
              activeNavClass="active"
              scrollDuration="800"
              headerBackground="true"
              className="ml-auto"
            >
              <ul className="navbar-nav ml-auto navbar-center" id="mySidenav">
                <li className="nav-item active">
                  <a href="#home" className="nav-link">
                    Inicio
                  </a>
                </li>
                <li className="nav-item">
                  <a href="#about" className="nav-link">
                    ¿Quiénes somos?
                  </a>
                </li>
                <li className="nav-item">
                  <a href="#services" className="nav-link">
                    Servicios
                  </a>
                </li>
                <li className="nav-item">
                  <a href="#clients" className="nav-link">
                    Clientes
                  </a>
                </li>
                <li className="nav-item">
                  <a href="#contact" className="nav-link">
                    Contacto
                  </a>
                </li>
              </ul>
            </ScrollspyNav>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
