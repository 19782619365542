import React, { useEffect } from "react";
import Navbar from "../components/Navbar/Navbar";
import Home from "./Home";
import About from "./About";
import Service from "./Service";
import Footer from "../components/Footer";
import FooterAlt from "../components/FooterAlt";
import { Client } from "../components/Carousel";
import Contact from "./Contact";

const Index = () => {
  useEffect(() => {
    document.getElementById("main_navbar").classList.add("navbar-light");
  }, []);

  return (
    <>
      {/* Navigation Menu */}
      <Navbar />

      {/* HomeSection Menu */}
      <Home />

      {/* About Menu */}
      <About />

      {/* Service Menu */}
      <Service />

      {/* Client Menu */}
      <Client />

      {/* Contact Menu */}
      <Contact />

      {/* Footer Menu */}
      <Footer />

      {/* FooterAlt Menu */}
      <FooterAlt />
    </>
  );
};

export default Index;
