import React from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <>
      <section className="section home-1-bg" id="home">
        <div className="home-8-bg-overlay"></div>
        <div className="home-center">
          <div className="home-desc-center">
            <div className="container">
              <Row className="justify-content-center">
                <Col lg="7">
                  <div className="home-1-title text-center">
                    <h1 className="text-dark font-weight-normal mb-0">
                      CEO Global EDB SAS
                    </h1>
                    <p className="text-muted home-subtitle mt-4 f-15 mb-0">
                      Somos una empresa joven altamente motivada y apasionada
                      por las tecnologías de información en su máxima expresión
                      y aplicación multipropósito en las organizaciones o ámbito
                      empresarial.
                    </p>
                    <div className="watch-video mt-5">
                      <a href="#about" className="btn btn-custom">
                        Sobre nosotros
                      </a>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>

            <div className="container-fluid">
              <Row>
                <div className="home-1-shape">
                  <img
                    src="images/home-shape.png"
                    alt=""
                    className="img-fluid mx-auto w-100 d-block"
                  />
                </div>
              </Row>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
